<template>
    <div>
        <div>
            <div v-if="!status">
                <input id="file" ref="file" type="file" @change="uploadFile" accept="video/*" class="hidden"/>
                <el-button type="primary" size="medium">
                    <i class="el-icon-plus"></i> 添加视频
                </el-button>
            </div>
            <div v-if="status" class="uploadInfo">
                <div>
                    <i class="el-icon-video-camera" style="font-size:36px;color:#409EFF"></i>
                </div>
                <div style="width:100%;margin-left:20px">
                    <div>{{name}}</div>
                    <el-progress v-if="percent!==0" :percentage="percent" style="margin-top:10px"></el-progress>
                </div>
            </div>
            <div v-if="status" style="text-align:right;font-size:14px;margin-top:20px;margin-right:20px">
                <span @click="remove" class="removeBtn">删除</span>
            </div>
        </div>
    </div>
</template>

<script>
import * as qiniu from 'qiniu-js'
import { getQiniuToken } from '../../api'
export default {
    name:'uploadItem',
    data(){
        return{
            percent:0,
            observable:'',
            subscription:'',
            status:false,
            name:""
        }
    },
    mounted(){

    },
    methods:{
        async uploadFile(){
            let file = this.$refs.file.files[0]
            this.name = file.name
            this.status = true
            const result = await this.getQiniuToken();
            if(result.data.code===0){
                const token = result.data.data
                this.observable = qiniu.upload(file, file.name, token)
                this.percent = 0
                const observer = {
                    next:(res)=>{
                        console.log(res)
                        this.percent = parseInt(res.total.percent)
                    },
                    error:(res)=>{
                        this.$message.warning('上传错误')
                    },
                    complete:(res)=>{
                        this.$message.success('上传成功')
                        this.$emit('confirmUpload',res)
                    }
                }
                this.subscription = this.observable.subscribe(observer)
            }else{
                return;
            }
        },
        stop(){
            this.subscription.unsubscribe()
        },
        async getQiniuToken(){
            return new Promise((resolve)=>{
                getQiniuToken().then(res=>{
                    resolve(res)
                })
            })
        },
        remove(){
            this.name = ''
            this.status = false
            this.$emit('removeFile')
        }
    }
}
</script>

<style>
.hidden{
    opacity: 0;
    position: absolute;
    height: 38px;
    width: 120px;
}
.uploadInfo{
    display: flex;
    align-items: center;
}
.removeBtn{
    cursor: pointer;
}
.removeBtn:hover{
    opacity: 0.6;
}
</style>