<template>
    <div>
        <div style="padding:20px 140px">
            <h3>文件上传
                <span style="font-size:12px;font-weight:normal;color:#606266">（推荐采用mp4、flv格式）</span>
            </h3>
            <uploadItem @confirmUpload="onConfirmUpload" @removeFile="confirmRemoveFile" style="margin-top:20px"/>
            <hr style="margin-top:30px"/>
            <h3 style="margin-top:30px">基本信息</h3>
            <el-form>
                <el-form-item label="视频名称">
                    <el-input v-model="form.video_name"></el-input>
                </el-form-item>
                <el-form-item label="视频简介">
                    <el-input v-model="form.video_intro" type="textarea" :rows="6"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submit">提交审核</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { updateVideo } from '../../api'
import { getUserId } from '../../utils/store'
import uploadItem from '../UploadItem'
export default {
    components:{
        uploadItem
    },
    data(){
        return{
            id:'',
            form:{
                video_name:'',
                video_intro:'',
                video_url:'',
                key:''
            }
        }
    },
    mounted(){

    },
    methods:{
        submit(){
            if(!this.form.video_name){
                this.$message.warning('请输入视频名称')
                return
            }
            if(!this.form.video_intro){
                this.$message.warning('请输入视频简介')
                return
            }
            if(!this.form.key){
                this.$message.warning('请上传视频')
                return
            }
            updateVideo(this.id,getUserId(),this.form.video_name,this.form.video_intro,this.form.key).then(res=>{
                if(res.data.code===0){
                    this.$message.success('上传成功')
                }
            })
        },　
        onConfirmUpload(e){
            this.form.key = e.key
        },
        confirmRemoveFile(){
            this.form.key = ''
        }
    }
}
</script>

<style>

</style>